import React from 'react'
import Layout from 'components/layout'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'

const NotFound = () => (
  <Layout isHome={false}>
    <br />
    <br />
    <br />
    <br />
    <Container>
      <Col style={{ textAlign: 'center', color: 'deepskyblue' }}>
        <h1>NOT FOUND</h1>
        <p>Looks like you hit a page that does not exist.</p>
      </Col>
    </Container>
  </Layout>
)
export default NotFound
